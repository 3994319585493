import styles from "./BookingPayment.module.scss";
import Direction from "./Direction/Direction";
import Hotel from "./Hotel/Hotel";
import Passengers from "./Passengers/Passengers";
import FAQ from "./FAQ/FAQ";
import SingleBreadCrumb from "../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import TicketWayInfoWrapper from "../Booking/TicketWayInfoWrapper/TicketWayInfoWrapper";
import Payment from "./Payment/Payment";
import {
    useGetOrderByIdQuery,
    useGetPaymentLinkQuery,
} from "../../services/orders.js";
import {useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {selectCounties} from "store/selectors/catalog";
import {selectLang} from "store/selectors/header";

const BookingPayment = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const orderId = params.id;
    const hourRenewal = params.hourRenewal ?? null;
    const {data: ordersQuery} = useGetOrderByIdQuery(
        {id: orderId},
        {skip: !orderId}
    );

    const currentLang = useSelector(selectLang);
    const {countries, countries_en} = useSelector(
        (state) => state.catalog.catalog
    );
    let citizenship = [];
    if (currentLang == "RU") citizenship = countries;
    else citizenship = countries_en;
    const passengerTypes = {
        adults: "взрослый",
        children: "ребёнок",
        babies: "младенец",
    };
    const [paymentError, setPaymentError] = useState(null);

    if (paymentError === null && searchParams.get("payment") == "failed") {
        setPaymentError(searchParams.get("message"));
    }

    const month = {
        "01": "янв",
        "02": "фев",
        "03": "мар",
        "04": "апр",
        "05": "май",
        "06": "июн",
        "07": "июл",
        "08": "авг",
        "09": "сен",
        10: "окт",
        11: "ноя",
        12: "дек",
    };

    const passengers = ordersQuery?.data.passengers;
    const itineraryReceiptAmount = passengers?.length;

    let passengersContent = [];
    if (passengers && passengers.length)
        passengersContent = passengers.map(function (elem) {
            return {
                birthDate: elem.birthday,
                citizenship: citizenship ? citizenship[elem.country] : "",
                documentNumber: elem.document_number,
                name: elem.lastname + " " + elem.firstname,
                sex: elem.gender === "female" ? "Женский" : "Мужской",
                ticketType: passengerTypes[elem.type],
            };
        });

    const trip_to = ordersQuery ? JSON.parse(ordersQuery?.data.trip_to) : "";
    const trip_back = ordersQuery
        ? JSON.parse(ordersQuery?.data?.trip_back)
        : "";

    const bookingValidity = {
        from: ordersQuery?.data.order_start_booking,
    };
    const hotel = {
        period:
            ordersQuery?.data.hotel_check_in +
            " - " +
            ordersQuery?.data.hotel_check_out,
        hasHotel: ordersQuery?.data.hotel_city ? true : false,
    };

    const departure_city = trip_to ? trip_to?.flight[0].departure_city : "";
    let departure_city_str = departure_city[Object.keys(departure_city)[0]];
    const arrival_city = trip_to
        ? trip_to.flight.slice(-1)[0].arrival_city
        : "";
    let arrival_city_str = arrival_city[Object.keys(arrival_city)[0]];
    let departure_timestamp = trip_to?.departure_timestamp * 1000;

    let [departure_date_day, departure_date_month, departure_date_year] =
        trip_to ? trip_to.departure_date.split(/\./) : [];
    const departure_date_str =
        departure_date_day + " " + month[departure_date_month];
    let [arrival_date_day, arrival_date_month, arrival_date_year] = trip_to
        ? trip_to.arrival_date.split(/\./)
        : [];
    const arrival_date_str = arrival_date_day + " " + month[arrival_date_month];

    const orderData = ordersQuery?.data;

    const directionInfo = trip_back !== null ? "both" : "to";

    const isReturnTikets = !!trip_to && !!trip_back;
    return (
        <section className={styles.bookingSuccess}>
            <div className="container">
                <div className={styles.bookingSuccess__wrapper}>
                    <SingleBreadCrumb
                        title={"Редактировать бронирование"}
                        path={"/booking/" + params.id}
                    />
                    <Payment
                        hotel={hotel}
                        bookingValidity={bookingValidity}
                        paymentError={paymentError}
                        orderId={orderId}
                        orderData={orderData}
                        passangerCount={itineraryReceiptAmount}
                        isReturnTikets={isReturnTikets}
                        cityFrom={departure_city_str}
                        cityTo={arrival_city_str}
                        hourRenewal={hourRenewal}
                        flightFromBookingExpiresAt={ordersQuery?.data.flight_from_booking_expires_at}
                        orderRenewal={ordersQuery?.data.order_renewal}
                        orderRenewalDay={ordersQuery?.data.order_renewal_day}
                        departureDateStr={departure_timestamp}
                    />
                    <Direction
                        fromTown={departure_city_str}
                        toTown={arrival_city_str}
                        startDate={departure_date_str}
                        endDate={arrival_date_str}
                    />
                    <Hotel
                        town={ordersQuery?.data.hotel_city}
                        startDate={ordersQuery?.data.hotel_check_in}
                        endDate={ordersQuery?.data.hotel_check_out}
                    />
                    <TicketWayInfoWrapper
                        directionInfo={directionInfo}
                        trip_to={trip_to}
                        trip_back={trip_back}
                    />
                    <Passengers passengers={passengersContent}/>
                </div>
            </div>
            <FAQ title={"Вопросы и ответы"}/>
        </section>
    );
};

export default BookingPayment;
