import SingleBreadCrumb from "../../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import TicketWayInfoWrapper from "../../Booking/TicketWayInfoWrapper/TicketWayInfoWrapper";
import AboutPurchase from "../../BookingSuccess/AboutPurchase/AboutPurchase";
import Direction from "../../BookingSuccess/Direction/Direction";
import Documents from "../../BookingSuccess/Documents/Documents";
import Payment from "./Payment/Payment";
import Hotel from "../../BookingSuccess/Hotel/Hotel";
import styles from "./BookingsInfo.module.scss";
import Passengers from "./Passengers/Passengers";
import { useGetOrderByIdQuery } from "../../../services/orders.js";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL, BASE_URL_FRONT } from "../../../api/constants";
import OrderRenewalBlock from "../../BookingSuccess/BlockOrderRenewal";

const BookingsInfo = () => {
    const params = useParams();
    const catalog = useSelector((state) => state.catalog.catalog);
    const citizenship = catalog?.countries;
    const orderId = params.id;
    const { data: getOrdersQuery, error } = useGetOrderByIdQuery({
        id: orderId,
    });
    const passengerTypes = {
        adults: "взрослый",
        children: "ребёнок",
        babies: "младенец",
    };

    const orderStatus = {
        created: "Не оплачено",
        payed: "Оплачено",
        processing: "Оформляется",
        finished: "Оформлено",
        canceled: "Отменено",
        expired: "Истекло",
    };

    const month = {
        "01": "янв",
        "02": "фев",
        "03": "мар",
        "04": "апр",
        "05": "май",
        "06": "июн",
        "07": "июл",
        "08": "авг",
        "09": "сен",
        10: "окт",
        11: "ноя",
        12: "дек",
    };

    const trip_to = getOrdersQuery
        ? JSON.parse(getOrdersQuery?.data.trip_to)
        : "";
    const trip_back = getOrdersQuery
        ? JSON.parse(getOrdersQuery?.data.trip_back)
        : "";
    let orderRenewal=getOrdersQuery?.data.order_renewal;
    let flightToBookingExpiresAt=getOrdersQuery?.data.flight_from_booking_expires_at;
    let tariff=getOrdersQuery?.data.tariff;
    let orderRenewalDay=getOrdersQuery?.data.order_renewal_day;

    let departure_city = trip_to?.flight
        ? trip_to?.flight[0].departure_city
        : "";
    let departure_city_str = departure_city[Object.keys(departure_city)[0]];
    let arrival_city = trip_to.flight
        ? trip_to.flight.slice(-1)[0].arrival_city
        : "";
    let arrival_city_str = arrival_city[Object.keys(arrival_city)[0]];

    let [departure_date_day, departure_date_month, departure_date_year] =
        trip_to.departure_date ? trip_to.departure_date.split(/\./) : [];
    let departure_date_str =
        departure_date_day + " " + month[departure_date_month];
    let [arrival_date_day, arrival_date_month, arrival_date_year] =
        trip_to.arrival_date ? trip_to.arrival_date.split(/\./) : [];
    let arrival_date_str = arrival_date_day + " " + month[arrival_date_month];

    const passengersContent = [];
    getOrdersQuery?.data.passengers.forEach(function (elem) {
        let passenger = {
            birthDate: elem.birthday,
            citizenship: citizenship ? citizenship[elem.country] : "",
            documentNumber: elem.document_number,
            name: elem.lastname + " " + elem.firstname,
            sex: elem.gender === "female" ? "Женский" : "Мужской",
            ticketType: passengerTypes[elem.type],
        };

        passengersContent.push(passenger);
    });

    const flightLink = () => {
        window.location.replace(
            `${BASE_URL}orders/download?id=` + orderId + "&booking_type=flight"
        );
    };

    const hotelLink = () => {
        window.location.replace(
            `${BASE_URL}orders/download?id=` + orderId + "&booking_type=hotel"
        );
    };

    const paymentClick = () => {
        window.location.replace(`${BASE_URL_FRONT}bookingPayment/` + orderId);
    };
    const directionInfo = trip_back !== null ? "both" : "to";
    const hotelBookingFile = getOrdersQuery?.data.hotel_booking_file;
    const flightBookingFile = getOrdersQuery?.data.flight_booking_file;
    const status = orderStatus[getOrdersQuery?.data.order_status];

    return (

        <div className={styles.info}>
            <SingleBreadCrumb title={"Назад"} path={"/profile/booking"} />
            <Direction
                fromTown={departure_city_str}
                toTown={arrival_city_str}
                startDate={departure_date_str}
                endDate={arrival_date_str}
            />
            <AboutPurchase
                date={getOrdersQuery?.data.order_start_booking}
                passengersQuantity={getOrdersQuery?.data.passengers.length}
                status={status}
                sum={
                    getOrdersQuery?.data.price +
                    " " +
                    getOrdersQuery?.data.currency
                }
                orderNumber={getOrdersQuery?.data.order_number}
                flightToPnr={getOrdersQuery?.data.flight_to_pnr}
                flightFromPnr={getOrdersQuery?.data.flight_from_pnr}
                flightToBookingExpiresAt={
                    getOrdersQuery?.data.flight_to_booking_expires_at
                }
                flightFromBookingExpiresAt={
                    getOrdersQuery?.data.flight_from_booking_expires_at
                }
                flightToAirUrl={getOrdersQuery?.data.flight_to_air_url}
                flightFromAirUrl={getOrdersQuery?.data.flight_from_air_url}
            />
            {status === "Не оплачено" ? (
                <Payment
                    paymentClick={paymentClick}
                    hotelBookingFile={hotelBookingFile}
                    flightBookingFile={flightBookingFile}
                />
            ) : null}
            {orderRenewal === true && flightToBookingExpiresAt != null ? (
                    <OrderRenewalBlock
                        orderRenewal={orderRenewal}
                        flightToBookingExpiresAt={flightToBookingExpiresAt}
                        tariff={tariff}
                        orderRenewalDay={orderRenewalDay}
                        paymentClick={paymentClick}
                    />
                ) : null}
            {hotelBookingFile || flightBookingFile ? (
                    <Documents
                        flightLink={flightLink}
                        hotelLink={hotelLink}
                        hotelBookingFile={hotelBookingFile}
                        flightBookingFile={flightBookingFile}
                    />
            ) : null}
            {getOrdersQuery?.data.hotel_city != null ? (
                <Hotel
                    startDate={getOrdersQuery?.data.hotel_check_in}
                    endDate={getOrdersQuery?.data.hotel_check_out}
                    town={getOrdersQuery?.data.hotel_city}
                />
            ) : (
                <></>
            )}
            <TicketWayInfoWrapper
                directionInfo={directionInfo}
                trip_to={trip_to}
                trip_back={trip_back}
            />
            <Passengers passengersData={passengersContent} />
        </div>
    );
};

export default BookingsInfo;
