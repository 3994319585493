import styles from "./Payment.module.scss";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomCheckbox from "../../../components/UI/Checkbox/CustomCheckbox";
import Input from "../../../components/UI/Input/Input";
import {ReactSVG} from "react-svg";
import file from "../../../assets/icons/fileComplete.svg";
import clock from "../../../assets/icons/smallClock.svg";
import home from "../../../assets/icons/home.svg";
import percent from "../../../assets/icons/percent-icon.svg";
import {useEffect, useState} from "react";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import {selectCurrency} from "store/selectors/header";
import {BASE_URL} from "api/constants";
import {
    daysBetween,
    getPassengerAmountString,
    getTariffPrice,
    getTariffsFlight,
    getTariffsHotel,
    pluralizeDay,
} from "utils/tariffService";
import {selectTariffs} from "store/selectors/catalog";

const paymentCurrency = {
    RUB: "RUB",
    USD: "USD",
};

const Payment = ({
                     bookingValidity,
                     orderId,
                     isErrors,
                     hotel,
                     passangerCount,
                     paymentError,
                     orderData,
                     isReturnTikets,
                     cityFrom,
                     cityTo,
                     flightFromBookingExpiresAt,
                     orderRenewal,
                     orderRenewalDay,
                     departureDateStr
                 }) => {
    const tariffs = useSelector(selectTariffs);
    const [termT, setTermT] = useState();
    const [priceT, setPriceT] = useState(0);
    const [priceH, setPriceH] = useState(0);
    const [discountPrc, setDiscountPrc] = useState();
    const [discount, setDiscount] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const currentCurrency = useSelector(selectCurrency);
    const passengersAmount = getPassengerAmountString(passangerCount);
    let  orderRenewalDayTmp = new Date(orderRenewalDay);
    let  flightFromBookingExpiresAtTmp = null
    if (flightFromBookingExpiresAt != null) {
        flightFromBookingExpiresAtTmp = new Date(flightFromBookingExpiresAt);
        if (flightFromBookingExpiresAtTmp > orderRenewalDayTmp) {
            flightFromBookingExpiresAtTmp = new Date(departureDateStr);
        }
    }
    /**
     * Установка цена тарифы
     */
    useEffect(() => {
        const tariffId = orderData?.tariff_id;
        const tariffHotelId = orderData?.hotel_tariff_id;
        if (tariffId) {
            const discountPrc = +orderData?.discountPrc;
            const bookingTiketTariffs = getTariffsFlight(tariffs);
            const bookingHotelTariffs = getTariffsHotel(tariffs);

            setDiscountPrc(discountPrc);
            /**
             * Текущий тариф
             */
            const currentTarrif = bookingTiketTariffs.find(
                (item) => item.id == tariffId
            );

            if (currentTarrif) {
                setTermT(currentTarrif?.term);
                const singlePrice = getTariffPrice(
                    currentCurrency?.code,
                    currentTarrif
                );
                setPriceT(singlePrice);
            }

            /**
             * Если выбран тариф отеля
             */
            if (tariffHotelId) {
                /**
                 * Текущий тариф отеля
                 */
                const currentTarrifHotel = bookingHotelTariffs.find(
                    (item) => item.id == tariffHotelId
                );
                if (currentTarrifHotel) {
                    const singlePriceHotel = getTariffPrice(
                        currentCurrency?.code,
                        currentTarrifHotel
                    );

                    setPriceH(singlePriceHotel);
                }
            }
        }
    }, [orderData, tariffs, currentCurrency]);

    useEffect(() => {
        let totalSum = 0;
        /**Кол-во пассажиров */
        totalSum += priceT * passangerCount;
        /**Если возвратный*/
        if (isReturnTikets) totalSum *= 2;
        totalSum += priceH;
        if (discountPrc) {
            let discountSum = Math.ceil((discountPrc * totalSum) / 100);
            totalSum -= discountSum;
            setDiscount(discountSum);
        }
        setTotalPrice(totalSum);
    }, [termT, priceT, priceH, discountPrc]);

    dayjs.extend(customParseFormat);
    dayjs.extend(utc);

    const [isChecked, setIsChecked] = useState(true);

    const redirectToPaymentTinkoff = (values, errors) => {
        window.location.replace(
            `${BASE_URL}payments/tinkoff/redirect?id=` + orderId
        );
    };

    const redirectToPaymentCloud = (values, errors) => {
        window.location.replace(
            `${BASE_URL}payments/cloudpayments/redirect?id=` + orderId
        );
    };

    return (
        <aside className={styles.payment}>
            <h2 className={styles.payment__title}>Ваше бронирование</h2>
            <div className={styles.payment__items}>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={file}/>
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Маршрутная квитанция
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {passangerCount} {passengersAmount}
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {isReturnTikets && (
                                <>
                                    2 направления ({cityFrom} - {cityTo},{" "}
                                    {cityTo} -{cityFrom})
                                </>
                            )}
                            {!isReturnTikets && (
                                <>
                                    1 направление ({cityFrom} - {cityTo})
                                </>
                            )}
                        </p>
                    </div>
                    <div className={styles.payment__item_price}>
                        <p>
                            {priceT} {currentCurrency?.symbol} x{" "}
                            {passangerCount} {isReturnTikets && <> x 2</>}
                        </p>
                    </div>
                </div>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={clock}/>
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Время действия брони
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            c{" "}
                            {flightFromBookingExpiresAt == null ? (dayjs(bookingValidity?.from)
                                    .utc(true)
                                    .local()
                                    .format("DD.MM.YYYY HH:mm")) :
                                (dayjs(orderRenewalDay)
                                    .utc(true)
                                    .format("DD.MM.YYYY HH:mm"))}
                        </p>
                    </div>
                    <div className={styles.payment__item_price}>
                        {orderRenewal === true && flightFromBookingExpiresAt != null ? (
                            <p> {daysBetween(flightFromBookingExpiresAtTmp, orderRenewalDay)} {pluralizeDay(daysBetween(flightFromBookingExpiresAtTmp, orderRenewalDay))}</p>
                        ) : (
                            <p> {Math.ceil(termT / 24)} {pluralizeDay(Math.ceil(termT / 24))}</p>
                        )}

                    </div>
                </div>
                {hotel.hasHotel && (
                    <div className={styles.payment__item}>
                        <div className={styles.payment__item_icon}>
                            <ReactSVG src={home}/>
                        </div>
                        <div className={styles.payment__item_text}>
                            <p className={styles.payment__item_title}>Отель</p>
                            <p className={styles.payment__item_subtitle}>
                                {"от 3 дней"}
                            </p>
                        </div>
                        <div className={styles.payment__item_price}>
                            <p>
                                {priceH} {currentCurrency?.symbol}
                            </p>
                        </div>
                    </div>
                )}
                {orderData?.discount > 0 && (
                    <div className={styles.payment__item}>
                        <div className={styles.payment__item_icon}>
                            <ReactSVG src={percent}/>
                        </div>
                        <div className={styles.payment__item_text}>
                            <p className={styles.payment__item_title}>
                                Промокод
                            </p>
                        </div>
                        <div className={styles.payment__item_discount}>
                            <p>
                                -{discount} {currentCurrency?.symbol}
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.payment__divider}>&nbsp;</div>
            <div className={styles.payment__sum}>
                <p className={styles.payment__sum_title}>Итого</p>
                <p className={styles.payment__sum_cost}>
                    {totalPrice} {currentCurrency?.symbol}
                </p>
            </div>
            {currentCurrency?.code == paymentCurrency.RUB && (
                <>
                    <CustomButton
                        classes={styles.payment__sum_btn}
                        primary={true}
                        fullWidth={true}
                        onClick={redirectToPaymentTinkoff}
                        type={"submit"}
                        disabled={!isChecked}
                    >
                        К оплате
                    </CustomButton>
                    <div className={styles.payment__title5}>
                        *Для оплаты иностранной картой выберите валюту “USD“
                    </div>
                </>
            )}
            {currentCurrency?.code == paymentCurrency.USD && (
                <>
                    <CustomButton
                        classes={styles.payment__sum_btn}
                        primary={true}
                        fullWidth={true}
                        onClick={redirectToPaymentCloud}
                        type={"submit"}
                        disabled={!isChecked}
                    >
                        К оплате в USD
                    </CustomButton>
                    <div className={styles.payment__title5}>
                        * Для оплаты нажмите “К оплате в USD”, а затем нажмите
                        “Иностранной картой”
                    </div>
                </>
            )}

            {isErrors && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon}/>
                    <p>Проверьте правильность заполнения всех полей</p>
                </div>
            )}
            {paymentError && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon}/>
                    <p>{paymentError}</p>
                </div>
            )}
        </aside>
    );
};

export default Payment;
