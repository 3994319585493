import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import styles from "./Paid.module.scss";
import done from "../../../assets/icons/done.png";
import Spinner from "../../../components/UI/Spinner/Spinner";
import dayjs from "dayjs";
import React from "react";
import {BASE_URL_FRONT} from "../../../api/constants";
import {ReactSVG} from "react-svg";
import warning from "../../../assets/icons/warning-gray.svg";
import exclamation from "../../../assets/icons/warning-icon.svg";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";

const Paid = ({
                  email,
                  routeLinkFlight,
                  routeLinkHotel,
                  flightBookingFile,
                  hotelBookingFile,
                  isOrderWithHotel,
                  orderRenewal,
                  flightToBookingExpiresAt,
                  tariff,
                  orderId,
                  orderRenewalDay
              }) => {
    const paymentClick = () => {
        window.location.replace(`${BASE_URL_FRONT}bookingPayment/` + orderId);
    };

    return (
        <div className={styles.bookingSuccess__top}>
            <img
                className={styles.bookingSuccess__top_img}
                src={done}
                alt="done"
            />
            <h1 className={styles.bookingSuccess__top_title}>
                Бронирование подтверждено!
            </h1>
            <p className={styles.bookingSuccess__top_text}>
                Вы можете скачать маршрутную квитанцию по кнопке ниже.
                <br/>
                Также маршрутная квитанция была выслана вам на почту{" "}
                <span>{email}</span>
            </p>
            {!flightBookingFile && (
                <p className={styles.bookingSuccess__top_download}>
                    Маршрутная квитанция будет доступна для скачивания в течение
                    15 минут.
                    {isOrderWithHotel && (
                        <>
                            Бронь отеля будет доступна для скачивания в течение
                            60 минут.
                        </>
                    )}
                </p>
            )}
            <div className={styles.bookingSuccess__top_buttons}>
                {flightBookingFile ? (
                    <CustomButton primary={true} onClick={routeLinkFlight}>
                        Скачать маршрутную квитанцию
                    </CustomButton>
                ) : (
                    <Spinner/>
                )}
                {hotelBookingFile ? (
                    <CustomButton lined={true} onClick={routeLinkHotel}>
                        Скачать бронь отеля
                    </CustomButton>
                ) : null}
            </div>
            {/*{orderRenewal === true && flightToBookingExpiresAt != null ? (*/}
            {/*    <>*/}
            {/*        <div className={styles.bookingSuccess__top_buttons}>*/}

            {/*                <CustomButton*/}
            {/*                    classes={styles.payment__btn}*/}
            {/*                    onClick={paymentClick}*/}
            {/*                    primary*/}
            {/*                >*/}
            {/*                    Продлить до {dayjs(flightToBookingExpiresAt)*/}
            {/*                    .utc(false)*/}
            {/*                    .add(orderRenewalDay, 'hour')*/}
            {/*                    .format("DD.MM.YYYY HH:mm")} <Tooltip*/}
            {/*                    content={"После оплаты заказа срок брони будет автоматически продлен на " + (tariff.term_start / 24) + " - " + (tariff.term_end / 24) + " " + pluralizeDay(tariff.term_end / 24)}*/}
            {/*                    children={<ReactSVG src={warning}/>}*/}
            {/*                />*/}
            {/*                </CustomButton>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*) : null*/}
            {/*}*/}
        </div>
    );
};

export default Paid;
