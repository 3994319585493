import style from "./Tickets.module.scss";
import "react-loading-skeleton/dist/skeleton.css";
import SearchPanel from "../MainPage/SearchPanel/SearchPanel";
import Filters from "./Filters";
import TimeFilters from "./TimeFilters";
import TakeOff from "../../assets/icons/take-off.svg";
import TakeOffBack from "../../assets/icons/take-off-back.svg";
import ArrowIcon from "../../assets/icons/arrow-black.svg";
import PlaneIcon from "../../assets/icons/airCompany.svg";
import {ReactSVG} from "react-svg";
import React, {useState, memo, useEffect} from "react";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import classNames from "classnames";
import NotFound from "./NotFound";
import Ticket from "./Ticket";
import {useHidePage} from "../../hooks/useHidePage";
import {useDispatch, useSelector} from "react-redux";
import {switchIsOpened} from "../../store/slices/filters";
import {TicketsLoading} from "./TicketsLoading";
import styles from "./Tickets.module.scss";

import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../../components/UI/Spinner/Spinner";
import {BASE_URL} from "api/constants";
import {setTickets} from "../../store/slices/tickets";
//import ProductCard from "./ProductCard";
//import Loader from "./Loader";

const urlParams = new URLSearchParams(window.location.search);
const date_end = urlParams.get('date_end');
const cityFrom = urlParams.get('city_from');
const cityTo = urlParams.get('city_to');

const sliders = [
    {
        id: 0,
        name: `Вылет ${cityFrom}`,
        title: (
            <div className={style.time_sliders_item_title}>
                <ReactSVG src={TakeOff}/>
                <p>Туда</p>
            </div>
        ),
    },
    {
        id: 1,
        name: `Прибытие ${cityTo}`,
    },
];

if (date_end) {
    sliders.push(
        {
            id: 2,
            name: `Вылет из ${cityTo}`,
            title: (
                <div className={style.time_sliders_item_title}>
                    <ReactSVG src={TakeOffBack}/>
                    <p>Обратно</p>
                </div>
            ),
        },
        {
            id: 3,
            name: `Прибытие в ${cityFrom}`,
        },
    )
}

const MemoizedSearchPanel = memo(SearchPanel);

const Tickets = () => {
    const [checkedTransfersFilters, setCheckedTransfersFilters] = useState([]);
    const [checkedAirlinesFilters, setCheckedAirlinesFilters] = useState([]);
    const [timeFilters, setTimeFilters] = useState(
        sliders.map((item) => [0, 24]));
    const [timeFiltersChanged, setTimeFiltersChanged] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const hideTicketsPage = useHidePage();
    const dispatch = useDispatch();
//console.log(state);
    const loading = useSelector((state) => state.tickets.loading);
    const tickets = useSelector((state) => state.tickets.tickets);
    const airlines = useSelector((state) => state.tickets.airlines);
    const pagination = useSelector((state) => state.tickets.pagination);
    const filterAirlines = Object.keys(airlines).map((item) => {
        return {
            id: item,
            name: (
                <>
                    <img
                        src={'https://mpics.avs.io/al_square/24/24/' + item + '.png'}
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = PlaneIcon;
                        }}
                    />
                    {airlines[item]}
                </>
            ),
        }
    })

    const filterTransfers = [];

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    let [ticketsToShow, setTicketsToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    if (tickets && tickets.length) {
        for (let ticket of tickets) {
            if ((ticket.to.flight.length === 1 || ticket.back?.flight.length === 1) && !filterTransfers.find(item => item.id === 0)) {
                filterTransfers.push({
                    id: 0,
                    name: "Без пересадок",
                })
            }
            if ((ticket.to.flight.length === 2 || ticket.back?.flight.length === 2) && !filterTransfers.find(item => item.id === 1)) {
                filterTransfers.push({
                    id: 1,
                    name: "1 пересадка",
                })
            }
            if ((ticket.to.flight.length > 2 || ticket.back?.flight.length > 2) && !filterTransfers.find(item => item.id === 2)) {
                filterTransfers.push({
                    id: 2,
                    name: "2 и более",
                })
            }
        }
    }

    const filterTickets = () => {
        if (!tickets || !Object.keys(tickets).length) {
            return null
        }

        let newTickets = tickets.filter(item =>
            item?.to?.departure_time?.substring(0, item?.to?.departure_time.indexOf(':')) >= timeFilters[0][0] &&
            item?.to?.departure_time?.substring(0, item?.to?.departure_time.indexOf(':')) < timeFilters[0][1] &&
            item?.to?.arrival_time?.substring(0, item?.to?.arrival_time.indexOf(':')) >= timeFilters[1][0] &&
            item?.to?.arrival_time?.substring(0, item?.to?.arrival_time.indexOf(':')) < timeFilters[1][1]
        )
        if (date_end) {
            newTickets = newTickets.filter(item =>
                item?.back?.departure_time?.substring(0, item?.back?.departure_time.indexOf(':')) >= timeFilters[2][0] &&
                item?.back?.departure_time?.substring(0, item?.back?.departure_time.indexOf(':')) < timeFilters[2][1] &&
                item?.back?.arrival_time?.substring(0, item?.back?.arrival_time.indexOf(':')) >= timeFilters[3][0] &&
                item?.back?.arrival_time?.substring(0, item?.back?.arrival_time.indexOf(':')) < timeFilters[3][1]
            )
        }
        if (checkedTransfersFilters.length) {
            newTickets = newTickets.filter((item) => {
                let state = false;
                checkedTransfersFilters.map((rule) => {
                    if (rule === 0 && (item.to.flight.length === 1 || item.back && item.back.flight.length === 1)) {
                        state = true;
                    } else if (rule === 1 && (item.to.flight.length === 2 || item.back && item.back.flight.length === 2)) {
                        state = true;
                    } else if (rule === 2 && (item.to.flight.length >= 3 || item.back && item.back.flight.length >= 3)) {
                        state = true;
                    }
                })
                return state;
            });
        }
        if (checkedAirlinesFilters.length) {
            newTickets = newTickets.filter(item => {
                for (let airline of Object.keys(item?.to?.airlines)) {
                    if (checkedAirlinesFilters.indexOf(airline) !== -1) {
                        return true
                    }
                }
                if (date_end) {
                    for (let airline of Object.keys(item?.back?.airlines)) {
                        if (checkedAirlinesFilters.indexOf(airline) !== -1) {
                            return true
                        }
                    }
                }
                return false
            })
        }
        if (newTickets.length != ticketsToShow.length)
            setTicketsToShow(newTickets);
        //return newTickets;
    }

    const timeoutError = useSelector((state) => state.tickets.timeoutError);

    //ticketsToShow = filterTickets();
    filterTickets();

    const filtersChanged = checkedTransfersFilters.length || checkedAirlinesFilters.length || timeFiltersChanged

    if (loading) {
        return (
            <TicketsLoading/>
        )
    }

    const setTransfersFilters = (
        isChecked,
        filterId,
        currentFilters,
        setFiltersFunc
    ) => {
        let newCheckedTransfersFilters = [];
        if (isChecked) {
            newCheckedTransfersFilters = [...currentFilters, filterId];
        } else {
            newCheckedTransfersFilters = currentFilters.filter(
                (item) => item !== filterId
            );
        }
        setFiltersFunc(newCheckedTransfersFilters);
    };

    const setTimeFilter = (value, index) => {
        const newTimeFilters = [...timeFilters];
        newTimeFilters[index] = value;
        setTimeFilters(newTimeFilters);
        setTimeFiltersChanged(true);
    };

    const clearAllFilters = () => {
        setCheckedTransfersFilters([]);
        setCheckedAirlinesFilters([]);
        const newSliders = sliders.map((item) => [0, 24]);
        setTimeFilters(newSliders);
        setTimeFiltersChanged(false);
    };


    const fetchMoreData = async () => {
        console.log(page);
        setPage(prevPage => prevPage + 1);
        try {
            const response = await fetch(`${BASE_URL}flights-cache?uuid=${pagination.uuid}&page=${page}`);
            const data = await response.json();
            dispatch(setTickets({tickets: [...tickets, ...data.data.flights]}));
            if (pagination.amount === tickets.length)
                setHasMore(false);


        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }

        console.log(page);
    };


    return (
        <div className={style.tickets_container}>
            <MemoizedSearchPanel className={style.tickets_search_panel}/>
            <div className={style.tickets_page}>
                <aside
                    className={classNames(style.tickets_page_filters, {
                        [style["tickets_page_filters_opened"]]: showFilters,
                    })}
                >
                    <div
                        className={style.tickets_page_filters_header}
                        onClick={() => {
                            setShowFilters(false);
                            dispatch(switchIsOpened(false));
                        }}
                    >
                        <ReactSVG src={ArrowIcon}/>
                        <p>Фильтры</p>
                    </div>
                    {filterTransfers.length > 1 && (
                        <Dropdown title={"Пересадки"} opened={true}>
                            <Filters
                                onChange={(isChecked, filterId) =>
                                    setTransfersFilters(
                                        isChecked,
                                        filterId,
                                        checkedTransfersFilters,
                                        setCheckedTransfersFilters
                                    )
                                }
                                filters={filterTransfers}
                                checkedFilters={checkedTransfersFilters}
                            />
                        </Dropdown>
                    )}
                    {
                        filterAirlines.length > 1 && (
                            <Dropdown title={"Авиакомпании"} opened={true}>
                                <Filters
                                    filters={filterAirlines}
                                    onChange={(isChecked, filterId) =>
                                        setTransfersFilters(
                                            isChecked,
                                            filterId,
                                            checkedAirlinesFilters,
                                            setCheckedAirlinesFilters
                                        )
                                    }
                                    checkedFilters={checkedAirlinesFilters}
                                />
                            </Dropdown>
                        )
                    }

                    <Dropdown title={"Вылет и прибытие"} opened={true}>
                        <TimeFilters
                            sliders={sliders}
                            timeFilters={timeFilters}
                            onChange={(value, index) =>
                                setTimeFilter(value, index)
                            }
                        />
                    </Dropdown>
                    <div className={style.filter_buttons}>
                        <CustomButton
                            classes={style.clear_filters}
                            lined
                            onClick={() => {
                                clearAllFilters();
                            }}
                            small
                        >
                            Сбросить фильтры
                        </CustomButton>
                        <CustomButton
                            classes={style.apply_filters}
                            primary
                            onClick={() => {
                                setShowFilters(false);
                                dispatch(switchIsOpened(false));
                            }}
                            small
                        >
                            Применить
                        </CustomButton>
                    </div>
                </aside>
                {!hideTicketsPage && (
                    <div className={style.tickets_page_results}>
                        {timeoutError ? (
                            <>
                                <h2 className={styles.notFound__title}>
                                    Слишком долгий запрос :(
                                </h2>
                                <p className={styles.notFound__text}>
                                    К сожалению из-за долгого запроса билеты не найдены, попробуйте повторить поисковый
                                    запрос заново нажав на кнопку “Найти”
                                </p>
                            </>
                        ) : (
                            !ticketsToShow || ticketsToShow && !ticketsToShow.length ? (
                                <NotFound filtersChanged={filtersChanged} resetFilters={clearAllFilters}/>
                            ) : (
                                <>
                                    <div>
                                        <CustomButton
                                            classes={style.open_filters}
                                            lined
                                            onClick={() => {
                                                setShowFilters(true);
                                                dispatch(switchIsOpened(true));
                                            }}
                                        >
                                            Фильтры
                                        </CustomButton>
                                        <InfiniteScroll
                                            dataLength={ticketsToShow.length}
                                            next={fetchMoreData}
                                            hasMore={hasMore}
                                            loader={<Spinner/>}
                                        >
                                            {ticketsToShow && ticketsToShow.map(ticket => (
                                                <Ticket key={ticket.id} ticket={ticket}/>
                                            ))}
                                        </InfiniteScroll>
                                        {/*<CustomButton*/}
                                        {/*  small*/}
                                        {/*  classes={style.tickets_page_showMore}*/}
                                        {/*  linedBlack*/}
                                        {/*  onClick={ () => loadingMoreFlights() }*/}
                                        {/*>*/}
                                        {/*    Показать еще*/}
                                        {/*</CustomButton>*/}
                                    </div>
                                </>
                            ))}
                    </div>
                )}
            </div>
            {showFilters && <div className={style.filters_background}/>}
        </div>
    );
};

export default Tickets;
