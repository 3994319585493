import React, {useEffect, useRef, useState} from "react";
import style from "./SearchPanel.module.scss";
import PlaceInput from "./PlaceInput";
import PassengersInput from "./PassengersInput";
import Calendar, {
    monthsEn,
    monthsRu,
} from "../../../components/UI/Calendar/Calendar";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import classNames from "classnames";
import CalendarIcon from "../../../assets/icons/calendar.svg";
import {ReactSVG} from "react-svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import ArrowIcon from "../../../assets/icons/arrow-black.svg";
import {useDispatch, useSelector} from "react-redux";
import {switchIsOpened} from "../../../store/slices/searchInputs";
import useIsMobile from "../../../hooks/useIsMobile";
import {useNavigate} from "react-router-dom";
import {useGetTicketsQuery} from "../../../services/tickets";
import {
    setAirlines,
    setAirportFromFullName,
    setAirportToFullName,
    setCityFrom,
    setCityTo,
    setLoading, setPagination,
    setTickets,
    setTimeLimits,
    setTimeoutError
} from "../../../store/slices/tickets";
import Popup from "../../../components/UI/Popup/Popup";
import swapImg from "../../../assets/img/arrow.svg";
import {selectLang} from "store/selectors/header";

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();

const formatDate = (date) => {
    if (!date) {
        return "";
    }
    const newDate = new Date(+date);
    let day = newDate.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = newDate.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    return day + "." + month + "." + newDate.getFullYear();
};

const SearchPanel = ({className}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const airport_from = urlParams.get("airport_from");
    const airport_to = urlParams.get("airport_to");
    const date_start = urlParams.get("date_start");
    const date_end = urlParams.get("date_end");
    const adults = urlParams.get("adults");
    const children = urlParams.get("children");
    const babies = urlParams.get("babies");
    const service_class = urlParams.get("service_class");
    const currentLang = useSelector(selectLang);
    const airportFromFullName =
        useSelector((state) => state.tickets.airportFromFullName) ||
        urlParams.get("airport_from_full_name");
    const airportToFullName =
        useSelector((state) => state.tickets.airportToFullName) ||
        urlParams.get("airport_to_full_name");
    const cityFrom =
        useSelector((state) => state.tickets.cityFrom) ||
        urlParams.get("city_from");
    const cityTo =
        useSelector((state) => state.tickets.cityTo) ||
        urlParams.get("city_to");

    let dates = [];
    if (date_start && date_end) {
        dates = [+date_start, +date_end];
    } else if (date_start && !date_end) {
        dates = [+date_start];
    }

    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [nextMonth, setNextMonth] = useState();
    const [activeDays, setActiveDays] = useState(dates);
    const [airportFrom, setAirportFrom] = useState(airport_from || "");
    const [airportTo, setAirportTo] = useState(airport_to || "");
    const [airportFromText, setAirportFromText] = useState("");
    const [airportToText, setAirportToText] = useState("");
    const [errors, setErrors] = useState({});
    const [activeTag, setActiveTag] = useState(service_class || "ECONOMY");
    const [passengers, setPassengers] = useState({
        adults: +adults || 1,
        children: +children || 0,
        babies: +babies || 0,
    });
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isFirstDate, setIsFirstDate] = useState(true);

    const wrapperRef = useRef(null);
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);

    const isMobile = useIsMobile();
    const isMobileCalendar = useIsMobile(420);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const {data, error, isFetching} = useGetTicketsQuery(
        {
            airport_from,
            airport_to,
            date_start: formatDate(date_start),
            date_back: date_end ? formatDate(date_end) : null,
            adults,
            children,
            babies,
            service_class,
        },
        {
            skip: !airport_from,
            refetchOnFocus: false,
        }
    );

    useEffect(() => {
        let timer = setTimeout(() => {
            setIsPopupVisible(true);
        }, 900000);
        let nextMonth = currentMonth == 11 ? 0 : currentMonth + 1;
        setNextMonth(nextMonth);
        return () => clearTimeout(timer);
    }, []);

    const formatDateForSearchInput = (date) => {
        const months = currentLang == "RU" ? monthsRu : monthsEn;
        if (!date) {
            return "";
        }
        const newDate = new Date(date);
        let formattedDate = newDate.getDate();
        const month = months[newDate.getMonth()];
        return formattedDate + " " + month;
    };
    useEffect(() => {
        if (data) {
            dispatch(setTickets({tickets: data.data.flights}));
            dispatch(setAirlines({airlines: data.data.all_airlines}));
            dispatch(setTimeLimits({timeLimits: data.data.timilimits}));
            dispatch(setPagination({
                uuid: data.data.has,
                page: data.data.page,
                amount: data.data.rows
            }));
        } else {
            dispatch(setTickets({tickets: {}}));
        }
    }, [data]);

    useEffect(() => {
        if (!error) {
            dispatch(setLoading({loading: isFetching}));
            dispatch(setTimeoutError({timeoutError: false}));
        } else {
            dispatch(setLoading({loading: false}));
            if (error.status === 504) {
                dispatch(setTimeoutError({timeoutError: true}));
            }
        }
    }, [isFetching]);

    useOutsideClick(
        wrapperRef,
        () => {
            setIsCalendarVisible(false);
            dispatch(switchIsOpened(false));
            setIsFirstDate(true);
        },
        inputRef1,
        inputRef2
    );

    const setMonths = (month) => {
        setMonth(month);
        if (month < 11) {
            setNextMonth(month + 1);
        } else {
            setNextMonth(0);
        }
    };

    const setActiveDaysFunction = (activeDay) => {
        setErrors({...errors, date: null});
        if (
            activeDays.length === 0 ||
            (isFirstDate && activeDays.length === 1)
        ) {
            setActiveDays([activeDay]);
            setIsFirstDate(false);
        } else {
            if (activeDays.length >= 2) {
                if (isFirstDate) {
                    activeDays.shift();
                } else {
                    activeDays.pop();
                }
            }
            setActiveDays(
                [...activeDays, activeDay].sort(function (a, b) {
                    return a - b;
                })
            );

            setIsCalendarVisible(false);
            setIsFirstDate(true);
            dispatch(switchIsOpened(!isCalendarVisible));
        }
    };

    const clearSecondDate = () => {
        const newActiveDays = [activeDays[0]];
        setActiveDays(newActiveDays);
        setIsCalendarVisible(false);
    };

    const firstDate = formatDateForSearchInput(activeDays[0]);
    const lastDate = formatDateForSearchInput(activeDays[1]);

    const handleSearch = () => {
        const newErrors = {...errors};
        if (!airportFrom) {
            newErrors.airportFrom = "Поле обязательно";
        }
        if (!airportTo) {
            newErrors.airportTo = "Поле обязательно";
        }
        if (!activeDays.length) {
            newErrors.date = "Поле обязательно";
        }
        setErrors(newErrors);
        const {adults, children, babies} = passengers;
        if (airportFrom && airportTo && activeDays.length) {
            let path = `/tickets?airport_from=${airportFrom}&airport_to=${airportTo}&adults=${adults}&children=${children}&babies=${babies}&service_class=${activeTag}&date_start=${activeDays[0]}&airport_from_full_name=${airportFromFullName}&airport_to_full_name=${airportToFullName}&city_from=${cityFrom}&city_to=${cityTo}`;
            if (activeDays[1]) {
                path += `&date_end=${activeDays[1]}`;
            }
            navigate(path);
            navigate(0);
        }
    };

    const handleChange = (field, callback, value) => {
        setErrors({...errors, [field]: null});
        callback(value);
    };

    const handleCalendarInputClick = (isFirstDate) => {
        setIsCalendarVisible(!isCalendarVisible);
        setIsFirstDate(isFirstDate);
        dispatch(switchIsOpened(!isCalendarVisible));
    };

    /**
     * Меняет местами аэропорта
     */
    const swapAirports = () => {
        if (isFetching) return;
        handleChange("airportFrom", setAirportFrom, airportTo);
        handleChange("airportTo", setAirportTo, airportFrom);
        setAirportFromText(airportToText);
        setAirportToText(airportFromText);
        dispatch(
            setAirportToFullName({
                airportToFullName: airportFromFullName,
            })
        );
        dispatch(
            setAirportFromFullName({
                airportFromFullName: airportToFullName,
            })
        );
        dispatch(
            setCityTo({
                cityTo: cityFrom,
            })
        );
        dispatch(
            setCityFrom({
                cityFrom: cityTo,
            })
        );
    };

    const handleScroll = () => {
        if (
            document.body.scrollHeight - 300 <
            window.scrollY + window.innerHeight
        ) {
            //setLoading(true);
            //handleSearch();
            //console.log(123);
        }
    };

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    window.addEventListener("scroll", debounce(handleScroll, 500));

    return (
        <div className={classNames(style.search_panel, className)}>
            <PlaceInput
                type={"from"}
                title={"Откуда"}
                value={airportFromText}
                onChange={(value) =>
                    handleChange("airportFrom", setAirportFrom, value)
                }
                onChangeText={(value) => setAirportFromText(value)}
                error={errors.airportFrom}
            />
            {airportTo && airportFrom && (
                <div className={style.search_panel_swap_btn}>
                    <div onClick={swapAirports}>
                        <img
                            className={style.search_panel_swap_btn__rtl}
                            src={swapImg}
                        />
                        <img src={swapImg}/>
                    </div>
                </div>
            )}
            <PlaceInput
                type={"to"}
                title={"Куда"}
                value={airportToText}
                onChange={(value) =>
                    handleChange("airportTo", setAirportTo, value)
                }
                onChangeText={(value) => setAirportToText(value)}
                error={errors.airportTo}
            />
            <div
                className={classNames(
                    style.search_panel_input,
                    style.search_panel_input_left
                )}
            >
                <div className={style.search_panel_input_container}>
                    {firstDate && (
                        <span className={style.search_panel_input_placeholder}>
                            Туда
                        </span>
                    )}
                    <input
                        ref={inputRef1}
                        value={firstDate}
                        onClick={() => handleCalendarInputClick(true)}
                        placeholder={"Туда"}
                        className={classNames({
                            [style["search_panel_input_with_text"]]: firstDate,
                            [style["hasError"]]: errors.date,
                        })}
                    />
                    <ReactSVG
                        className={style.search_panel_input_icon}
                        src={CalendarIcon}
                        onClick={() => handleCalendarInputClick(true)}
                    />
                </div>
                {errors.date && (
                    <span className={style.search_panel_input_error}>
                        {errors.date}
                    </span>
                )}
                {isCalendarVisible && (
                    <div
                        ref={isCalendarVisible ? wrapperRef : null}
                        className={style.calendars}
                    >
                        <div
                            className={style.search_panel_opened_header}
                            onClick={() => handleCalendarInputClick(true)}
                        >
                            <ReactSVG src={ArrowIcon}/>
                            Даты
                        </div>
                        <div className={style.calendars_inputs}>
                            <input
                                className={style.search_panel_opened_input}
                                placeholder={"Туда"}
                                value={firstDate}
                                readOnly={isMobile}
                            />
                            <input
                                className={style.search_panel_opened_input}
                                placeholder={"Обратно"}
                                value={lastDate}
                                readOnly={isMobile}
                            />
                        </div>
                        <div className={style.calendars_container}>
                            <Calendar
                                currentYear={year}
                                currentMonth={month}
                                changeCurrentMonth={(month) => setMonths(month)}
                                changeCurrentYear={(currentYear) => {
                                    setYear(currentYear);
                                }}
                                activeDays={activeDays}
                                setActiveDays={(activeDays) =>
                                    setActiveDaysFunction(activeDays)
                                }
                                showLeftIcon
                                showRightIcon={isMobileCalendar}
                            />
                            {!isMobileCalendar && (
                                <Calendar
                                    currentYear={
                                        nextMonth !== 0 ? year : year + 1
                                    }
                                    currentMonth={nextMonth}
                                    changeCurrentMonth={() =>
                                        setMonths(nextMonth)
                                    }
                                    changeCurrentYear={() => {
                                        setYear(
                                            nextMonth !== 0 ? year : year + 1
                                        );
                                    }}
                                    activeDays={activeDays}
                                    setActiveDays={(activeDays) =>
                                        setActiveDaysFunction(activeDays)
                                    }
                                    showRightIcon
                                />
                            )}
                        </div>
                        {activeDays[0] && (
                            <CustomButton
                                classes={style.calendars_button}
                                lined
                                small
                                onClick={() => {
                                    clearSecondDate();
                                    dispatch(
                                        switchIsOpened(!isCalendarVisible)
                                    );
                                }}
                            >
                                Обратный билет не нужен
                            </CustomButton>
                        )}
                    </div>
                )}
            </div>
            <div
                className={classNames(
                    style.search_panel_input,
                    style.search_panel_input_right
                )}
            >
                <div className={style.search_panel_input_container}>
                    {lastDate && (
                        <span
                            className={
                                style.search_panel_input_right_placeholder
                            }
                        >
                            Обратно
                        </span>
                    )}
                    <input
                        ref={inputRef2}
                        value={lastDate}
                        className={classNames(style.search_panel_input, {
                            [style["search_panel_input_with_text"]]: lastDate,
                        })}
                        onClick={() => handleCalendarInputClick(false)}
                        placeholder={"Обратно"}
                    />
                    <ReactSVG
                        className={style.search_panel_input_icon}
                        src={CalendarIcon}
                        onClick={() => handleCalendarInputClick(false)}
                    />
                </div>
            </div>
            <PassengersInput
                activeTag={activeTag}
                passengers={passengers}
                setActiveTag={(value) => setActiveTag(value)}
                setPassengers={(value) => setPassengers(value)}
            />
            <CustomButton
                classes={style.search_button}
                secondary
                onClick={handleSearch}
            >
                Найти
            </CustomButton>
            <Popup
                open={isPopupVisible && window.location.pathname === "/tickets"}
                onCancel={() => setIsPopupVisible(false)}
                title={
                    <>
                        <p>Результаты могли устареть</p>
                        <span>
                            Обновите поиск, чтобы увидеть актуальные билеты
                        </span>
                    </>
                }
                footer={
                    <div>
                        <CustomButton
                            linedBlack
                            onClick={() => setIsPopupVisible(false)}
                        >
                            Позже
                        </CustomButton>
                        <CustomButton
                            primary
                            onClick={() => {
                                setIsPopupVisible(false);
                                handleSearch();
                            }}
                        >
                            Обновить
                        </CustomButton>
                    </div>
                }
            />
        </div>
    );
};

export default SearchPanel;
